import { useState } from "react";
import { db } from "../firebase"; // Adjust the path based on your structure
import { collection, addDoc } from "firebase/firestore";

export default function ReviewPage() {
  const [username, setUsername] = useState("");
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const addReview = async () => {
    if (!username || !comment || rating === 0) {
      setError("Alle velden zijn verplicht.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await addDoc(collection(db, "reviews"), {
        username,
        rating,
        comment,
        accepted: false,
        reviewed: false,
        createdAt: new Date(),
      });

      setSubmitted(true);
      setUsername("");
      setRating(0);
      setComment("");
    } catch (err) {
      console.error("Fout bij het verzenden van de review:", err);
      setError("Er is iets misgegaan. Probeer het opnieuw.");
    }

    setLoading(false);
  };

  return (
    <div className="h-[500px] mx-8 flex items-center justify-center p-6">
      <div className="bg-white p-8 w-full max-w-lg">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          Geef je review
        </h2>
        {error && (
          <div className="mb-4 p-3 text-red-700 bg-red-100 border border-red-400 rounded">
            {error}
          </div>
        )}
        {submitted ? (
          <div className="text-center">
            <p className="text-green-600 text-lg font-semibold">
              ✅ Je review is verzonden!
            </p>
          </div>
        ) : (
          <div>
            <input
              className="w-full p-3 border rounded-lg mb-3 focus:ring-2 focus:ring-blue-400 outline-none text-lg"
              placeholder="Naam"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <div className="flex justify-center space-x-2 mb-3">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  className={`text-3xl transition-transform ${
                    star <= rating ? "text-yellow-500 scale-110" : "text-gray-300"
                  }`}
                  onClick={() => setRating(star)}
                >
                  ★
                </button>
              ))}
            </div>
            <textarea
              className="w-full p-3 border rounded-lg mb-3 focus:ring-2 focus:ring-blue-400 outline-none text-lg"
              placeholder="Schrijf je review"
              rows={3}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
            <button
              className="w-full py-3 text-white bg-yellow-600 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-yellow-300"
              onClick={addReview}
              disabled={loading}
            >
              {loading ? "Versturen..." : "Review versturen"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
